#Education_0 .block-wrapper > .text-wrapper > .khbhdgsd9u-editor_css {
  text-shadow: none;
}
#Education_0 .block-wrapper > .text-wrapper > .khbhpbnanxl-editor_css {
  height: 100px;
  float: left;
}
#Education_0 .image-wrapper > .name-wrapper > .khbht445oq-editor_css {
  display: none;
}
#Education_0 .image-wrapper > .name-wrapper > .khbht62yuqh-editor_css {
  display: none;
}
#Education_0 .image-wrapper > .name-wrapper > .khbhv9top7g-editor_css {
  display: none;
}
#Education_0 .image-wrapper > .name-wrapper > .khbhveqqnt-editor_css {
  display: none;
}
#Education_0 .timeline > .block-wrapper > .khbhluvo33p-editor_css {
}
#Header_0 .khbhzfyt7p6-editor_css {
  justify-content: flex-end;
}
#Header_0 .home-page > .khbhzl6vppi-editor_css {
}
#Header_0.khbhze58ues-editor_css {
  text-align: justify;
}
#Education_0 .timeline > .block-wrapper > .khbhdejyyl-editor_css {
}
#Skills_0 .ant-row > .khbikifz2g-editor_css {
  text-align: left;
}
#Skills_0 .khbib86zvuo-editor_css {
  justify-content: flex-end;
  text-align: right;
}
#Header_0.khbi3zsq5o-editor_css {
  position: fixed;
}
#Profile_0 .banner-anim > .banner-anim-elem > .khbhh39wjo-editor_css {
  top: 64px;
}
#Header_0 .khbinowuyta-editor_css {
  right: -100px;
}
#Header_0 .home-page > .khbiormmq5m-editor_css {
  display: none;
}
#Education_0 .timeline > .block-wrapper > .khbhvdzbq3q-editor_css {
}
#Education_0 .block-wrapper > .text-wrapper > .khbixeeu8ge-editor_css {
  font-size: 14px;
}
#Education_0 .block-wrapper > .text-wrapper > .khbj40pfifi-editor_css {
  font-size: 14px;
}
#Education_0 .block-wrapper > .text-wrapper > .khbixmu8ijb-editor_css {
  font-size: 14px;
}
#Education_0 .block-wrapper > .text-wrapper > .khbj47j9w6d-editor_css {
  font-size: 14px;
}
#Education_0 .khbhdhmpw5-editor_css {
  align-items: flex-start;
  text-align: justify;
  min-height: 600px;
}
#Education_0 .home-page > .khbhhmooykr-editor_css {
  left: -40px;
}
#Skills_0 .ant-row > .khbiac6fajp-editor_css {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  max-width: 50%;
  left: 25px;
  float: right;
}
#Profile_0.khbi3cxvitr-editor_css {
  background-clip: padding-box;
  height: 530px;
  background-color: rgba(0, 0, 0, 0);
}
#Profile_0
  .banner-anim-elem
  > .Profile-content-wrapper
  > .khbjtloc1or-editor_css {
  display: flex;
  justify-content: center;
}
#Footer2_0 .home-page > .copyright > .khbk1pqwlgo-editor_css {
  display: none;
}
#Contact_0 div > .khbk5dybu8r-editor_css {
  width: 200px;
}
#Profile_0
  .banner-anim-elem
  > .Profile-content-wrapper
  > .khbi5o2euan-editor_css {
  font-size: 18px;
}
#Work_0 div > .ant-row > .khbkrjskr2-editor_css {
  width: 100%;
}
#Work_0 .ant-col > .content3-text > .khbkt8lwtxo-editor_css {
  font-size: 18px;
}
#Work_0 .ant-col > .content3-text > .khbkqghz32f-editor_css {
  text-align: justify;
  width: 100%;
}
#Contact_0.khbk5zp9zub-editor_css {
  background-clip: ;
  background-color: rgba(0, 0, 0, 0);
  background-image: url('');
}
#Education_0.khbhit7hpi8-editor_css {
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0);
}
#Honor_0 .ant-row > .ant-col > .khbm26twfwp-editor_css {
  justify-content: space-between;
  font-size: 20px;
}
#Honor_0 .ant-row > .ant-col > .khbm65dtxa8-editor_css {
  overflow: hidden;
}
#Honor_0 .ant-row > .ant-col > .khbm6jj7mcb-editor_css {
  width: 300px;
  height: 200px;
}
#Honor_0 .ant-row > .ant-col > .khbmilftyc-editor_css {
  text-align: justify;
  width: 300px;
  height: 200px;
}
#Honor_0 .ant-row > .ant-col > .khbmlmcf31t-editor_css {
  width: 300px;
}
#Honor_0 .ant-row > .ant-col > .khbmm8gcwth-editor_css {
  height: 55px;
}
#Honor_0 .ant-row > .ant-col > .khbmnua32o7-editor_css {
  text-align: justify;
  width: 300px;
}
#Honor_0 .ant-row > .ant-col > .khbm3x7fq9r-editor_css {
  font-size: 16px;
}
#Honor_0 .ant-row > .ant-col > .khbm69suvo-editor_css {
  width: 300px;
  height: 190px;
}
#Honor_0.khbm43k2y06-editor_css {
  color: rgb(214, 237, 255);
  background-color: rgb(250, 250, 250);
  background-image: linear-gradient(rgba(253, 253, 253, 0.3) 0%, white 100%);
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto;
  background-clip: padding-box;
}
#Work_0.khbkq60tnjc-editor_css {
  background-color: rgb(250, 250, 250);
  background-image: linear-gradient(
    to bottom,
    rgb(250, 250, 250) 0%,
    white 100%
  );
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto;
  background-clip: padding-box;
}
#Project_0 .ant-col > .Project-block-group > .khbp8taxkou-editor_css {
  display: none;
}
#Project_0 .ant-row > .ant-col > .khbpa7d8n6o-editor_css {
  height: 100%;
  min-height: 250px;
}
#Project_0 .ant-row > .ant-col > .khbpaow69g-editor_css {
  height: 100%;
  min-height: 250px;
}
#Project_0 .ant-row > .ant-col > .khbparq57ab-editor_css {
  height: 100%;
  min-height: 265px;
}
#Project_0 .ant-col > .Project-block-group > .khbpo8r2acf-editor_css {
  text-align: justify;
}
#Project_0 .ant-col > .Project-block-group > .khbpq8i0l9-editor_css {
  text-align: justify;
}
#Project_0 .ant-col > .Project-block-group > .khbpqbww92j-editor_css {
  text-align: justify;
}
